<template>
  <div :class="`bg-white d-flex flex-column ${isWide ? 'px-30' : isMobile ? '' : 'px-15'}`">
    <div :class="`${isMobile ? 'mt-5 mx-10' : 'mt-10 mx-15'} d-flex justify-content-between vertical-center`">
      <span :class="`font-bold ${isMobile ? 'font-15' : 'font-25'}`">{{ $t('employees') }}</span>
      <v-btn :class="`vertical-center ${isMobile ? 'button-small' : 'button-normal'}`" @click="$router.push(`/add_employee`)">
        <i :class="`${isMobile ? 'mx-3' : 'mx-5'} fa fa-plus font-12 color-white`" />
        <span :class="`${isMobile? 'mr-3' : 'mr-5'}`">{{ $t('add_employee') }}</span>
      </v-btn>
    </div>
    <div :class="`${isMobile ? 'mt-2 mx-10' : 'mt-5 mx-15'} d-flex justify-content-start vertical-center pointer`" @click="goBackToEditDrink()" v-if="editDrinkEventId">
      <i :class="`${isMobile ? 'font-12' : 'font-20'} mr-2 color-icon fa fa-angle-left`" />
      <span :class="`font-bold ${isMobile ? 'font-12' : 'font-15'}`">{{ $t('Getränkekarte bearbeiten') }}</span>
    </div>
    <div :class="`d-flex ${isWide ? 'px-20 py-10 justify-content-between' : 'p-5 flex-column'}`" v-if="myEventList">
      <div class="w-100 d-flex justify-content-between vertical-center" v-if="!isWide && !visibleFilters">
        <div class="w-100 mr-5 box-search d-flex justify-content-between vertical-center">
          <i class="ml-5 fa fa-search font-20 color-icon" />
          <input class="w-100 px-5" v-model="keyword" @keydown.enter.exact.prevent="refreshData" />
          <v-btn class="mx-2" icon small @click="keyword = ''; refreshData();" :disabled="!keyword">
            <i :class="`font-20 flaticon-circle ${keyword.length > 0 ? 'color-pink' : ''}`"></i>
          </v-btn>
          <v-btn class="m-1 button-round-20" @click="refreshData()">
            <span class="font-12">{{ $t('find') }}</span>
          </v-btn>
        </div>
        <v-btn icon @click="visibleFilters = true">
          <img :src="assets.filter" :height="40" />
        </v-btn>
      </div>
      <div :class="`d-flex flex-column ${isWide ? 'w-25' : 'w-100'}`" v-if="isWide || visibleFilters">
        <span :class="`mt-5 mb-2 ${isMobile ? 'font-12' : 'font-15'} font-bold`">{{ $t('employee_name') }}</span>
        <input class="w-100 mt-1 input-normal" v-model="keyword" @keydown.enter.exact.prevent="refreshData" />
        <span :class="`mt-5 mb-2 ${isMobile ? 'font-12' : 'font-15'} font-bold`">{{ $t('role') }}</span>
        <div class="d-flex justify-content-start vertical-center">
          <span :class="`${isMobile ? 'font-12' : 'font-15'} ${roleType === -1 ? 'box-selected' : 'box-normal'}`" @click="roleType = -1">{{ $t('all') }}</span>
          <span :class="`mx-5 ${isMobile ? 'font-12' : 'font-15'} ${roleType === EMPLOYEE_ROLE_TICKET ? 'box-selected' : 'box-normal'}`" @click="roleType = EMPLOYEE_ROLE_TICKET">{{ $t('ticket') }}</span>
          <span :class="`${isMobile ? 'font-12' : 'font-15'} ${roleType === EMPLOYEE_ROLE_DRINK ? 'box-selected' : 'box-normal'}`" @click="roleType = EMPLOYEE_ROLE_DRINK">{{ $t('drink') }}</span>
        </div>
        <span :class="`mt-5 mb-2 ${isMobile ? 'font-12' : 'font-15'} font-bold`">{{ $t('status') }}</span>
        <div class="d-flex justify-content-start vertical-center">
          <span :class="`${isMobile ? 'font-12' : 'font-15'} ${status === -1 ? 'box-selected' : 'box-normal'}`" @click="status = -1">{{ $t('all') }}</span>
          <span :class="`mx-5 ${isMobile ? 'font-12' : 'font-15'} ${status === 1 ? 'box-selected' : 'box-normal'}`" @click="status = 1">{{ $t('active') }}</span>
          <span :class="`${isMobile ? 'font-12' : 'font-15'} ${status === 0 ? 'box-selected' : 'box-normal'}`" @click="status = 0">{{ $t('inactive') }}</span>
        </div>
        <div class="mt-10 d-flex justify-content-between">
          <v-btn class="button-clear-filter" @click="keyword = ''; roleType = -1; status = -1; refreshData();">
            <i class="font-15 flaticon-circle color-icon"></i>
            <span class="mx-5 font-12">{{ $t('clear_filters') }}</span>
          </v-btn>
          <v-btn class="button-round-20" @click="refreshData()">
            <span class="font-12">{{ $t('apply_filters') }}</span>
          </v-btn>
        </div>
      </div>
      <div :class="isWide ? 'pl-10 w-75' : 'w-100'">
        <div v-if="items.length > 0">
          <div :class="`w-100 d-flex flex-column ${isWide ? 'px-20 py-5' : isMobile ? 'py-2' : 'p-5'} `" v-for="(item, index) in items" :key="(item, index)">
            <div class="w-100 d-flex justify-content-between vertical-center">
              <div class="w-100 d-flex flex-column">
                <span :class="`font-bold ${isMobile ? 'font-12' : 'font-15'}`">{{ item.name }}</span>
                <span :class="`mt-2 font-label ${isMobile ? 'font-10' : 'font-12'}`">{{ item.eventName }}</span>
                <span :class="`mt-3 font-bold color-pink ${isMobile ? 'font-10' : 'font-12'}`">{{ $t('role') }}: {{ $t(item.role === EMPLOYEE_ROLE_TICKET ? 'ticket' : 'drink') }}</span>
                <div :class="`mt-2 ${isMobile ? 'd-flex justify-content-between' : ''} vertical-center pointer`" @click="item.inUse = !item.inUse; setDrinkInfoTask(item);">
                  <span :class="`font-bold ${isMobile ? 'font-12' : 'font-15 mr-10'}`">{{ $t('in_use') }}</span>
                  <img  :src="item.inUse ? assets.switch_on : assets.switch_off" :height="isWide ? 25 : 15" />
                </div>
              </div>
              <div class="m-5 d-flex flex-column">
                <v-btn icon @click="$router.push(`/edit_employee/${item.employeeId}`)">
                  <i class="fa fa-edit color-pink font-20"></i>
                </v-btn>
                <v-btn icon @click="deleteEmployeeInfoTask(item.employeeId)">
                  <i class="ml-1 flaticon-delete-1 color-red font-20"></i>
                </v-btn>
              </div>
            </div>
            <hr v-if="index < items.length - 1" />
          </div>
        </div>
        <div class="my-30 py-40 center" v-else>
          <p>{{ $t('no_data_found') }}</p>
        </div>
      </div>
    </div>
    <div class="my-30 py-40 center" v-else>
      <b-spinner type="grow"></b-spinner>
      <p class="mt-5">{{ $t('alert_please_wait') }}</p>
    </div>
  </div>
</template>

<style lang="scss">
  .button-clear-filter {
    padding: 20px !important;
    border-radius: 20px !important;
    border: 1px solid #B91AA8 !important;
    background-color: white !important;
  }
</style>

<script>
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../main';
import { showLoading, showFunctionError } from '../../../functions';

import filter from '@/assets/images/filter.png';
import switch_on from '@/assets/images/switch_on.png';
import switch_off from '@/assets/images/switch_off.png';

export default {
  name: 'EmployeeList',
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    isWide() {
      return this.$store.state.isWide;
    },
    myInfo() {
      return this.$store.state.myInfo;
    },
    employeeList() {
      return this.$store.state.employeeList;
    },
    myEventList() {
      return this.$store.state.myEventList;
    }
  },
  watch: {
    employeeList() {
      this.refreshData();
    },
    myEventList() {
      this.refreshData();
    }
  },
  data() {
    return {
      assets: {
        filter,
        switch_on,
        switch_off
      },
      editDrinkEventId: null,
      roleType: -1,
      status: -1,
      visibleFilters: false,
      keyword: '',
      address: '',
      items: []
    };
  },
  mounted() {
    if (this.checkPermission()) {
      this.editDrinkEventId = localStorage.getItem('eventboxEditDrinkEventId');
      this.refreshData();
    }
  },
  methods: {
    checkPermission() {
      if (this.myInfo) {
        if (this.myInfo.userType === this.USER_ORGANIZER) {
          return true;
        } else {
          this.$toast.error('No permission!!');
          this.$router.push('/');
          return false;
        }
      } else {
        this.$toast.info(this.$t('alert_sign_in'));
        this.$router.push('/');
        return false;
      }
    },
    goBackToEditDrink() {
      if (this.editDrinkEventId) {
        localStorage.setItem('eventboxEditDrinkEventId', '');
        this.$router.push(`/edit_drink/${this.editDrinkEventId}`);
      }
    },
    refreshData() {
      if (this.myEventList) {
        const items = [];
        const keyword = this.keyword.toLowerCase();
        this.employeeList.forEach(element => {
          const eventInfo = this.getEventInfo(element.eventId);
          if (this.filterEmployee(element, eventInfo, keyword)) {
            element['eventName'] = eventInfo.name;
            items.push(element);
          }
        });
        items.sort((a, b) => (a.createdAt.seconds < b.createdAt.seconds) ? 1 : -1);
        this.items = items;
      } else {
        this.items = [];
      }
      this.visibleFilters = false;
    },
    getEventInfo(eventId) {
      if (this.myEventList) {
        return this.myEventList.find(element => element.eventId === eventId);
      }
      return null;
    },
    filterEmployee(employeeInfo, eventInfo, keyword) {
      if (!eventInfo) {
        return false;
      }
      if (keyword && !employeeInfo.name.toLowerCase().includes(keyword) && !eventInfo.name.toLowerCase().includes(keyword)) {
        return false;
      }
      if (this.roleType != -1 && this.roleType != employeeInfo.role) {
        return false;
      }
      if (this.status != -1 && ((this.status == 0 && employeeInfo.inUse) || (this.status == 1 && !employeeInfo.inUse))) {
        return false;
      }
      return true;
    },
    setDrinkInfoTask(drinkInfo) {
      if (!this.checkPermission()) {
        return;
      }
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, 'setEmployeeInfo');
      func(JSON.stringify(drinkInfo)).then(response => {
        loader.hide();
        if (response.data === this.RESULT_SUCCESS) {
          this.$toast.success(this.$t('alert_updated_successfully'));
        } else {
          showFunctionError(this, response.data);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    },
    deleteEmployeeInfoTask(employeeId) {
      if (!this.checkPermission()) {
        return;
      }
      if (!confirm(this.$t('confirm_delete'))) {
          return;
        }
      const params = {
        isDelete: true,
        userId: this.myInfo.userId,
        employeeId: employeeId
      };
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, 'setEmployeeInfo');
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        if (response.data === this.RESULT_SUCCESS) {
          this.$toast.success(this.$t('alert_deleted_successfully'));
        } else {
          showFunctionError(this, response.data);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    }
  }
};
</script>